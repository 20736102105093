<template>
  <div v-if='false' />
</template>

<script>
import mixins from '.././mixins.js';

export default {
  props: ['timer','active'],
  mixins: [mixins],
  methods: {
    startTimer: function() {
      setTimeout(() => this.$root.$emit('next'), this.timestampToSeconds(this.timer) * 1000);
    }
  },
  watch: {
    active: function() {
      if(this.active == true && this.timer){
        this.startTimer();
      }
    }
  },
}

</script>
