<template>
  <figure v-if='filename'>
    <img :src='src'>
  </figure>
</template>

<script>
import mixins from '.././mixins.js';

export default {
  mixins: [mixins],
  props: ['filename'],
  computed: {
    src : function() {
      return this.kirby.page.files[this.filename].src;
    }
  }
}
</script>

<style scoped type="text/css">

  figure {
    padding: 0;
    margin: 0;
    position: relative;
    z-index: -1;
    top: -3rem;
  }

  img {
    padding: 0;
    margin: auto;
    position: absolute;
    z-index: -1;
    top: 0;
    bottom:3.5rem;
    height: calc( 100vh - 20rem);
    left: 0;
    right: 0;
    width: 100%;
    object-fit: contain;
  }

</style>
