<template>
  <form id='Esivalmistelu'>
    <h2>{{ slide.content.questiontext }}</h2>
    <v-select id='Kuntavalikko'
              v-model='kunta'
              :options='slide.content.questionoptions'
              @input='post(slide.content.question, kunta)' />
  </form>
</template>

<style type="text/css">
  #Kuntavalikko {
    background-color: #ff5100;
    color: #294634;
    border-radius: 1em;
    padding: 1em;
    position: relative;
  }

  form#Esivalmistelu {
    padding: 0 0em;
    position: relative;
    top: -6rem;
    font-size: 2em;
  }

  .vs__dropdown-toggle {
    border: none;
  }
</style>

<script>
import 'vue-select/dist/vue-select.css';
import mixins from '../.././mixins.js';

export default {
  name: 'Esivalmistelu',
  mixins: [mixins], //kirby(), urltopath(), etc
  props: ['slide'],
  data: function(){
    return {
      kunta: '',
    }
  },
}
</script>
