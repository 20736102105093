<template>
  <h2 v-if='slide.content' v-html='slide.content.text' style='{ backgroundColor : slide.content.palette }' />
</template>

<style type="text/css" scoped>
  h2 {
    font-family: 'SimplonNorm-Light';
    font-size: 5em;
    font-weight: 100;
    /*padding: 12em;*/
    transform: translateY(-3rem);
  }
</style>

<script>
import mixins from '../.././mixins.js';

export default {
  mixins: [mixins], //kirby(), urltopath(), etc
  props: ['slide']
}
</script>
