<template>
  <radial-progress-bar class='timer'
                       :diameter='83'
                       :completed-steps='secondsCounted'
                       :total-steps='end'
                       start-color='white'
                       stop-color='white'
                       inner-stroke-color='#ff5100'>
    <label>{{ fmtMSS(end - secondsCounted) }}</label>
  </radial-progress-bar>
</template>

<script>
import RadialProgressBar from 'vue-radial-progress'

export default {
  components: {
    RadialProgressBar
  },
  props: ['end', 'showtime', 'autocontinue', 'warning'],
  data () {
    return {
      secondsCounted: 0,
      warned: false,
      timer: null
    }
  },
  mounted () {
    this.$root.$on('startTimer', this.start);
  },
  beforeDestroy () {
    this.cancel();
  },
  computed: {
    secondsLeft: function() {
      return this.end - this.secondsCounted;
    }
  },
  watch: {
    secondsCounted: {
      immediate: true,
      deep: true,
      handler: 'isFinished'
    }
  },
  methods: {
    start () {
      this.timer = setInterval(this.tick, 1000);
    },
    tick () {
      this.secondsCounted = this.secondsCounted + 1;
    },
    cancel () {
      clearInterval(this.timer);
      this.secondsCounted = 0;
    },
    fmtMSS(s){
      if(s > 0) {
        return (s-(s%=60))/60+(9<s?':':':0')+s
      }
      return '0:00'
    },
    isFinished(){
      if(this.secondsCounted > this.end) {
        clearInterval(this.timer);
        if(this.autocontinue) {
          this.$root.$emit('next')
        } else {
          this.$root.$emit('activateNextArrow');
        }
      } else if(this.secondsCounted > (this.end - 60) && !this.warned) {
        this.warned = true;
        this.playWarning();
      }
    },
    playWarning(){
      var audioElement = new Audio(this.warning.src);
      audioElement.play();
    }
  },
}
</script>

<style>
  .timer {
      position: relative;
      top: -5px;
  }

  label {
    font-size: 1.2rem;
  }
</style>
