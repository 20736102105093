<template>
  <button @click='generateReport()'>
    <slot />
    <vue-html2pdf id="html2Pdf"
                  :show-layout="false"
                  :float-layout="false"
                  :enable-download="true"
                  :preview-modal="false"
                  pdf-format="a4"
                  :manual-pagination="true"
                  :pdf-content-width="1400"
                  :paginate-elements-by-height="2100"
                  pdf-orientation="portrait"
                  ref="html2Pdf">
      <resultForm :submissionProp='submissionID' slot='pdf-content' />
    </vue-html2pdf>
  </button>
</template>


<style scoped>

  #html2Pdf {
    width: 100vw;
    height: 100vh;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    pointer-events: none;
    opacity: 0;
  }

  button {
    font-family: 'SimplonMono-Light';
    font-weight: Light;
    font-size: 1.4rem;
    position: fixed;
    left: 0;
    right: 0;
    margin: auto;
    width: 21rem;
    height: 6rem;
    border-radius: 6rem;
    outline: none;
    border: none;
    will-change: transform;
    transition: transform 0.25s;
    background-color: var(--orange);
    color: white;
    cursor: pointer;
  }

</style>


<script>
import VueHtml2pdf from 'vue-html2pdf'

export default {
  props: ['submissionID'],
  components: {
    VueHtml2pdf
  },
  methods: {
    generateReport () {
      this.$refs.html2Pdf.generatePdf()
    },
  }
}
</script>
