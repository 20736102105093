<template>
  <footer>
    <timer :end='timer'
           :autocontinue='autocontinue'
           v-if='timer && active'
           :warning='warning' />
    <figure>
      <audio ref='audioPlayer'
             :src='audio'
             preload='auto'
             crossorigin='anonymous'
             @timeupdate='timeupdate'
             @ended='ended' />
      <av-line
        ref-link='audioPlayer'
        :canv-width='60'
        :canv-height='60'
        :cors-anonym='true'
        :fft-size='32'
        line-color='#ffffff'
        :canv-top='true' />
    </figure>
    &ensp;
    <a class='toggle' @click='toggle()'>
      <playorpause :paused='paused' />
    </a>
  </footer>
</template>

<script>
import mixins from '.././mixins.js';

export default {
  mixins: [mixins], //kirby(), urltopath(), etc
  props: ['url', 'active', 'autocontinue', 'timer', 'after', 'before', 'warning'],
  data: function() {
    return {
      paused: false,
      progress: 0,
    }
  },
  beforeDestroy() {
    if(this.$refs.audioPlayer) this.$refs.audioPlayer.pause();
    this.paused = false;
  },
  methods: {
    toggle: function() {
      if(!this.$refs.audioPlayer.paused) {
        this.$refs.audioPlayer.pause();
        this.paused = true;
      } else if(this.$refs.audioPlayer.paused) {
        this.$refs.audioPlayer.play();
        this.paused = false;
      }
    },
    timeupdate: function() {
      this.progress = this.$refs.audioPlayer ? ((this.$refs.audioPlayer.currentTime / this.$refs.audioPlayer.duration) * 100) : 0;
    },
    ended: function() {
      if(this.autocontinue=='true' && !this.after){
        this.$root.$emit('next');
      }
      if(this.autocontinue=='false' && !this.timer){
        this.$root.$emit('activateNextArrow');
      }
      if(this.after > 0) {
        setTimeout(() => this.$root.$emit('next'), (this.after * 1000));
      }
      this.$root.$emit('startTimer');
    },
    markerStyle: function() {
      return 'left:' + this.progress +  '%'
    },
    playFromBeginning: function() {
      if(this.$refs.audioPlayer) this.$refs.audioPlayer.currentTime = 0;
      if(this.$refs.audioPlayer) this.$refs.audioPlayer.play();
    },
    activeListener: function() {
      //Play when active
      if(this.active && this.$refs.audioPlayer) {
        if(this.before > 0) {
          setTimeout(() => this.playFromBeginning(), (this.before * 1000));
        } else {
          this.playFromBeginning();
        }
      }
      else if(this.active && !this.$refs.audioPlayer) {
        //Takaisin mentäessä tarvitaan pikku reset:
        let self = this;
        setTimeout(function(){ self.activeListener() }, 50);
      }
      //Pause when not active
      if(!this.active && this.$refs.audioPlayer) {
        this.$refs.audioPlayer.pause();
      }
    },
  },
  watch: {
    active: {
      immediate: true,
      deep: true,
      handler: 'activeListener'
    }
  },
  computed: {
    audio: function() {
      var url = new URL(this.url)
      return url.pathname ;
    }
  }
}
</script>


<style scoped type="text/css">
  a {
    cursor: pointer;
  }

  big {
    font-family: 'SimplonNorm-Light';
    letter-spacing: 0.02em;
    font-size: 3.8rem;
    display: block;
    padding: 12rem;
  }

  audio {
    width: 100%;
    height: 100%;
    position: absolute;
    visibility: hidden;
  }

  footer {
    font-size: 2rem;
    position: fixed;
    height: 6rem;
    bottom: 5rem;
    left: 0rem;
    right: 12rem;
    z-index: 999;
    box-sizing: border-box;
    display: flex;
  }


  footer > figure {
    display: inline-block;
    box-sizing: border-box;
    padding: 0.33rem 0 0;
    margin: 0;
    width: 6rem;
    height: 6rem;
    background-color: #ff5100;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
  }

  a.toggle {
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    padding: 0;
    width: 6rem;
    height: 6rem;
    background-color: #ff5100;
    border-radius: 50%;
    font-size: 2.5rem;
    line-height: 0.8em;
    color: white;
    will-change: transform;
    transition: transform 0.25s;
  }

  a.toggle:hover {
    opacity: 0.95;
    transform: scale(1.02);
    transform-origin: center center;
  }


    footer > * {
      margin-right: 1rem;
    }
</style>
