<template>
  <main>
    <header>
      <div class="logos"><wiseLogo class="logo" />&emsp;&emsp;<tutkimusLogo class="logo" /></div>
      <h2 v-if='answers[3]'>
        {{answers[3].answer}}
      </h2>
      <time v-if='store.submission && store.submission.updated_at'>{{store.submission.updated_at.split(' ')[0]}}</time>
    </header>

    <section class='intro' v-if='kirby.site.results' v-html='kirby.site.results' />

    <dl v-if='answers'>
      <div v-for='(answer, index) in answersinorder' :key='index'>
        <dt v-if='answer.type == "control_textarea"' v-html='answer.text' />
        <dd style='white-space: pre-line' v-if='answer.type == "control_textarea"' v-html='answer.answer' />
      </div>
    </dl>
  </main>
</template>

<script>
import mixins from '.././mixins.js';
import axios from 'axios';
import _ from 'lodash';

export default {
  mixins: [mixins], //kirby(), urltopath(), etc
  props: ['submissionProp'],
  data: function(){
    return {
      store: {
        loaded: false,
        submission: []
      }
    }
  },
  computed: {
    answersinorder: function() {
      return _.sortBy(this.answers, function (obj) {
        return parseInt(obj.order, 10);
      });
    },
    answers: function() {
      if('answers' in this.store.submission) {
        //console.log('answers', this.store.submission);
        return this.store.submission.answers
      } else {
        console.log('No answers');
        return [];
      }
    }
  },
  mounted: function () {
    this.getData()
  },
  methods: {
    async getData() {
      try {
        const http = axios.create({ method: 'GET', credentials: 'include', mode: 'no-cors', headers: { Accept: '*/*',}});
        var self = this;
        if(this.submissionProp){
          http.get('jotformresults?submission=' + this.submissionProp).then(function(response){
            self.store.submission = response.data.submission;
            self.store.loaded = true;
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
  }
}
</script>

<style scoped>

  main {
    font-size: 1.25em;
    background-color: white;
    color: var(--green);
    padding: 4rem;
  }

  header {
    display: flex;
    justify-content: space-between;
  }

  section.intro {
    font-family: 'SimplonNorm-Light';
    font-weight: lighter;
    font-size: 2rem;
    padding-top: 9rem;
    padding-bottom: 3rem;
  }

  div.logos {
    display: flex;
    width: 30rem;
  }

  svg.logo {
    height: 3rem;
    fill: var(--green);
  }

  dl div {
    display: flex;
    justify-content: space-between;
  }

  dt, dd {
    border-top: 1px solid var(--green);
    margin: 0;
    padding: 2rem 2rem 4rem 0;
    width: calc(50% - 1rem);
    box-sizing: border-box;
  }

  dd {
    font-size: 0.7em;
  }
</style>
