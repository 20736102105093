<template>
  <main :class='[(currentBackgroundColor != "#C8C8C8" && currentBackgroundColor !== false) ? "nega slideshow" : "slideshow"]'
        :style='{ backgroundColor : currentBackgroundColor }'>
    <transition-group tag='div'
                      class='transition'
                      :name='name'>
      <section class='slide'
               v-show='index == current'
               v-for='(slide, index) in slides'
               :key='slide.id'>
        <slot :slide='slide'
              :active='index === current'
              v-if='index == current || (index == current + 1) || (index == current - 1)' />
      </section>
    </transition-group>
  </main>
</template>

<script>
module.exports = {
  props: ['slides'],
  data: function(){
    return {
      current: 0,
      name: 'slide-left',
      nextSlide: '',
      prevSlide: ''
    }
  },
  mounted: function(){
    //Listeners
    this.$root.$on('setCurrent', this.setCurrent);
    this.$root.$on('next', this.next);
    this.$root.$on('prev', this.prev);
    this.$root.$on('clear', this.clear);
  },
  computed: {
    count() {
      return this.slides.length;
    },
    currentSlide() {
      return (this.slides.length && this.current) ? this.slides[this.current] : false;
    },
    currentBackgroundColor() {
      return this.currentSlide.content ? (this.currentSlide.content.palette) : false;
    }
  },
  watch: {
    current: function() {
      this.$root.$emit('updatecurrent', this.current);
      this.$emit('changeType', this.slides[this.current].type);
      this.$emit('changeCurrent', this.current);
    }
  },
  methods: {
    setCurrent(current){
      this.current = current;
    },
    clear(){
      this.current = 0;
    },
    next(){
      this.nextSlide = (this.current < this.count-1) ? this.slides[this.current + 1] : false;
      if(this.nextSlide) {
        this.name = (this.nextSlide.content.transition == 'slide') ? 'slide-left' : '';
        this.current = this.current + 1;
      } else {
        //this.$root.$emit('nextchapter');
      }
    },
    prev(){
      this.prevSlide = (0 < this.current) ? this.slides[this.current - 1] : false;
      if(this.prevSlide) {
        this.name = (this.prevSlide.content.transition == 'slide') ? 'slide-right' : '';
        this.current = (0 < this.current) ? this.current - 1 : this.count-1;
      }
    },
  }
}
</script>

<style scoped>

  main.slideshow {
    height: 100%;
    overflow: hidden;
    will-change: backgroundColor, color;
    transition: 1s ease;
    color: #294634;
  }

  main.slideshow .slide label, main.slideshow .slide p {
    will-change: color;
    transition: 1s ease;
  }

  main.gray.slideshow {
    color: #294634;
  }

  main.nega.slideshow {
    color: rgb(255,255,255);
  }

  div.transition {
    height: 100%;
  }

  section {
    font-size: 12px;
    position: absolute;
    z-index: 1;
    padding: 0;
    margin: 0;
    left: 4.5rem;
    right: 4.5rem;
    top: 0rem;
    bottom: 0rem;
    will-change: opacity, transform;
    transform: translate3d(0,0,0);
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  @media screen and (min-width: 1400px) {
    section {
      font-size: 0.94vw;
    }
  }

  @media screen and (min-width: 2000px) {
    section {
      font-size: 18px;
    }
  }


  .slide-right-leave-active {
    transition: 0.33s 0s
  }
  .slide-right-enter-active {
    transition: 0.33s 0.33s
  }
  .slide-right-enter {
    transform: translate(-2%, 0);
    opacity: 0;
  }
  .slide-right-enter-to {
    opacity: 1;
    transform: translate(0%, 0);
  }
  .slide-right-leave-to {
    opacity: 0;
    transform: translate(2%, 0);
  }

  .slide-left-leave-active {
    transition: 0.33s 0s
  }
  .slide-left-enter-active {
    transition: 0.33s 0.33s
  }
  .slide-left-enter {
    transform: translate(2%, 0);
    opacity: 0;
  }
  .slide-left-enter-to {
    opacity: 1;
    transform: translate(0%, 0);
  }
  .slide-left-leave-to {
    opacity: 0;
    transform: translate(-2%, 0);
  }
</style>
