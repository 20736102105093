<template>
  <main id='Default' v-if='kirby.page.template == "default" || kirby.page.template == "set"'>
    <header>
      <a @click='prev()'>↲ Edellinen sivu </a><!--<router-link :to='"harjoitus/tulokset/" + submissionID'>{{submissionID}}</router-link>--><!-- | Submission ID: {{ submissionID }} {{ kirby.page.strings.title }} | -->
      <!--<masterclock />-->
      <slideProgress :current='current'
                     :max='kirby.page.content.slides.slides.length'
                     :slides='kirby.page.content.slides.slides' />
    </header>

    <slideshow @changeCurrent='changeCurrent'
               @changeType='changeCurrentSlideType'
               :slides='kirby.page.content.slides.slides'
               v-slot='{ slide, active }'>
      <textslide v-if='slide.type == "text"' :slide='slide' />
      <esivalmistelu v-if='slide.type == "esivalmistelu"' :slide='slide' />
      <jotform v-if='slide.type == "jotform"'
               :slide='slide'
               :warning='kirby.page.content.warning ? kirby.page.files[kirby.page.content.warning.value.replace("- ", "")] : false'
               :active='active' />
      <videoslide v-if='slide.type == "video"' :slide='slide' :active='active' />
      <audioslide v-if='slide.type == "audio"'
                  :slide='slide'
                  :warning='kirby.page.content.warning ? kirby.page.files[kirby.page.content.warning.value.replace("- ", "")] : false'
                  :active='active' />
      <counterslide v-if='slide.type == "counter" && active'
                    :slide='slide'
                    :page='kirby.page.uri'
                    :active='active' />
      <titleslide v-if='slide.type == "otsikko"' :slide='slide' />
    </slideshow>

    <footer>
      <navigation :type='currentSlideType' :submission='submissionID' :amount='kirby.page.content.slides.slides.length' />
    </footer>
  </main>
</template>

<style scoped>

  main {
    height: 100%;
  }

  header {
    font-family: 'SimplonMono-Medium';
    font-size: 1.2em;
    position: absolute;
    z-index: 3;
    padding: 3rem 4.5rem;
    color: #ff5100;
    cursor: pointer;
    left: 0;
    right: 0;
    top: 0;
  }

  footer {
    padding: 3rem 3rem 5rem;
    color: #ff5100;
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 2;
    box-sizing: border-box;
  }
</style>

<script>
import mixins from '.././mixins.js';

export default {
  mixins: [mixins], //kirby(), urltopath(), etc
  data: function(){
    return {
      currentSlideType: false,
      current: 0
    }
  },
  mounted: function() {
    window.onbeforeunload = function(e) {
      return true;
    };
  },
  methods: {
    changeCurrent: function(current) {
      this.current = current;
    },
    changeCurrentSlideType: function(type) {
      this.currentSlideType = type;
    },
    prev: function() {
      this.$root.$emit('prev');
    }
  }
}
</script>
