<template>
  <nav ref='seeker'
       @mousemove='seek'
       @mouseout='seeked = 0'
       @click='setCurrent'>
    <span>{{precentage}}</span> <meter min='0' :max='maxprecentage' :value='balancedcurrent' />
  </nav>
</template>

<script>

export default {
  props: ['max', 'current', 'slides'],
  data: function(){
    return {
      seeked: 0
    }
  },
  computed: {
    maxprecentage: function() {
      if(this.slides) {
        var n=0;
        var total=0;
        while (n < this.max) {
          var multiplier = !('multiplier' in this.slides[n].content ) ? 1 : this.slides[n].content.multiplier || 1;
          total = total + 1 * multiplier;
          n++;
        }
        return total
      } else {
        return 0
      }
    },
    balancedcurrent: function(){
      var n=0;
      var balancedcurrent=0;
      while (n <= this.current) {
        var multiplier = !('multiplier' in this.slides[n].content ) ? 1 : this.slides[n].content.multiplier || 1;
        balancedcurrent = balancedcurrent + 1 * multiplier;
        n++;
      }
      return balancedcurrent
    },
    precentage: function(){
      return Math.round(this.balancedcurrent / this.maxprecentage * 100) + '%'
    },
    currentOrSeeked: function(){
      return (this.seeked > 0) ? this.seeked : this.current;
    }
  },
  methods: {
    seek: function(event) {
      this.seeked = Math.round((event.offsetX / this.$refs.seeker.offsetWidth) * this.max);
    },
    setCurrent: function() {
      this.$root.$emit('setCurrent', this.seeked);
    }
  }
}
</script>

<style scoped>
  nav {
    position: absolute;
    top: 3rem;
    right: 3rem;
    vertical-align: top;
  }

  span {
    font-family: 'SimplonMono-Light';
    font-size: 2rem;
    position: absolute;
    top: 0.2rem;
    left: -5.5rem;
    width: 5rem;
    text-align: right;
    display: inline-block;
  }

  meter {
    box-sizing: border-box;
    width: 15rem;
    height: 2.5rem;
    margin: 0;
    padding: 0;
    border-radius: 2em;
    border: 0.25rem solid transparent;
    outline: none;
    overflow: hidden;
    background-color: var(--orange);
    background-image: none;
  }

  meter::-webkit-meter-bar {
    box-sizing: border-box;
    height: 2rem;
    background: var(--orange);
    outline: none;
  }

  meter::-webkit-meter-inner-element {
    box-sizing: border-box;
    height: 2rem;
    background: none;
  }

  meter::-webkit-meter-optimum-value {
    box-sizing: border-box;
    height: 100%;
    padding-right: 1.5em;
    background: white;
    border-radius: 3em;
  }

  :-moz-meter-optimum::-moz-meter-bar {
    background-color: white;
    background-image: none;
    height: 2rem;
    padding-right: 1.5em;
    border-radius: 3em;
  }

</style>
