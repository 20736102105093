<template>
  <nav>
    <GlobalEvents />
    <downloadButton v-if='submission && (current + 3 > amount)' :class='{"hide" : (current < (amount - 1))}' :submissionID='submission'>
      {{kirby.site.tallenna}}
    </downloadButton>
    <!-- Forward Button -->
    <button :class='{"tallenajasiirry" : (type == "jotform"), "goahead" : goahead, "hide" : current + 2 > amount }'
            @click='next()'
            v-if='amount > 1'>
      <label v-show='type=="jotform"'>{{kirby.site.eteenpain}}</label> <arrow direction='right' />
    </button>
  </nav>
</template>

<script>
import mixins from '.././mixins.js';

export default {
  mixins: [mixins], //kirby()
  props: ['amount', 'type', 'submission'],
  emits: ['prev', 'next'],
  data: function(){
    return {
      current: 0,
      goahead: false
    }
  },
  mounted: function() {
    this.$root.$on('updatecurrent', this.updatecurrent);
    this.$root.$on('activateNextArrow', this.activateNextArrow);
    this.$root.$on('next', this.clear);
    this.$root.$on('prev', this.clear);
  },
  methods: {
    updatecurrent: function(input) {
      this.current = input;
    },
    next: function() {
      this.$root.$emit('next');
      this.goahead = false;
    },
    prev: function() {
      this.$root.$emit('prev');
      this.goahead = false;
    },
    activateNextArrow: function() {
      this.goahead = true;
    },
    clear: function(){
      //console.log('all cleared!');
      this.goahead = false;
    }
  }
}
</script>

<style type="text/css" scoped>
  nav {
    display: flex;
    justify-content: space-between;
    justify-content: flex-end;
    width: 100%;
    position: relative;
    bottom: 0;
    left: 0;
    right: 0;
    box-sizing: border-box;
    z-index: 100;
  }

  div.counter {
    width: 5.5rem;
    text-align: center;
    vertical-align: bottom;
  }

  button {
    font-family: 'SimplonNorm-Light';
    font-weight: Light;
    font-size: 3rem;
    position: relative;
    width: 6rem;
    height: 6rem;
    border-radius: 6rem;
    outline: none;
    border: none;
    will-change: transform;
    transition: transform 0.25s;
    background-color: white;
  }

  .tallenajasiirry {
    white-space: nowrap;
    padding-left: 3rem;
    border-radius: 6rem;
    background-color: transparent;
    text-align: left;
    color: var(--orange);
    text-transform: uppercase;
    letter-spacing: 0.025em;
    position: relative;
    z-index: 2;
    overflow: hidden;
  }

  button {
    transition: 0.2s ease;
    cursor: pointer;
  }

  button.tallenajasiirry:active:hover {
    width: 6rem !important;
  }

  button.tallenajasiirry:active:focus {
    width: 6rem;
  }

  button.tallenajasiirry {
    width: 28.5rem;
  }

  button.hide {
    pointer-events: none;
    opacity: 0;
    width: 0rem;
  }

  label {
    vertical-align: middle;
    display: inline-block;
    margin-top: -3px;
  }

  label + div {
    position: absolute;
    right: 0;
    top: 0;
    width: 3.33rem;
    display: inline-block;
    background-color: white;
    margin-top: -1px;
    padding: 1.3rem;
    border-radius: 50%;
    margin-left: 1rem;
  }

  label + div + svg {
    display: inline-block;
  }

  @media screen and (max-width: 750px) {
    button { }
  }

  button:hover {
    transform: scale(1.05);
    transform-origin: center center;
  }

  button.goahead {
    transform: scale(1);
    animation: pulse 2s infinite;
  }

  @keyframes pulse {
	0% {
		transform: scale(0.90);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
	}

	100% {
		transform: scale(0.90);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}
</style>
