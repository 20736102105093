<template>
  <form ref='form' method='get'>
    <fieldset v-if='!sent && kirby.page.content.calltoaction'>
      <label v-html='kirby.page.content.calltoaction.value' />
      <input v-model='email' :placeholder='kirby.page.strings.placeholder' type='email'><button v-html='kirby.page.strings.button' v-on:click.prevent='submitForm(email)' />
      <small v-html='kirby.page.content.small.value' />
    </fieldset>
    <fieldset v-if='sent' class='orange'>
      <div v-html='kirby.page.content.response ? kirby.page.content.response.value : "Kiitos tilauksesta"' />
    </fieldset>
  </form>
</template>

<script>
import mixins from '.././mixins.js';
import axios from 'axios';

export default {
  mixins: [mixins],
  data: function(){
    return {
      email: '',
      sent: false
    }
  },
  methods: {
    submitForm: function (email) {
      var self = this;
      if(!self.sent) {
        axios.get('/mailer?email=' + email).then(function(response){
          console.info(response);
          self.sent = true;
        });
        axios.get('/jotformemails?email=' + email).then(function(response){
          console.info(response);
          self.sent = true;
        });
      }
      /*this.$refs.form.submit();*/
    }
  }
}
</script>

<style scoped type="text/css">


form {
  font-family: 'SimplonNorm-Light';
  letter-spacing: -0.010em;
  font-size: 1.85rem;
  line-height: 1.175em;
  width: 56rem;
  padding: 0;
  margin-top: 5rem;
  margin-bottom: 5rem;
  margin: auto;
  box-sizing: border-box;
  border: 1px solid var(--orange);
  border-top: 0px solid transparent;
  border-bottom: 0px solid transparent;
}



form input {
  background-color: var(--green);
  color: white;
  outline: none;
  border: none;
  font-size: inherit;
  padding: 0.5em 1em 0.6em;
  width: 19.5em;
  margin-top: 2.25rem;
  margin-bottom: 2.5rem;
  box-sizing: border-box;
  border-radius: 0.1em;
}

form button {
  display: inline-block;
  color: white;
  font-size: 0.75em;
  font-family: 'SimplonMono-Light';
  border-radius: 2em;
  background-color: #ff5100;
  padding: 0.85em 1.95em 0.85em;
  transition: background-color 0.2s ease;
  text-transform: uppercase;
  margin-top: 2rem;
  margin-bottom: 2.5rem;
}

button:hover {
  color: #ff5100;
  background-color: white;
  cursor: pointer;
}

fieldset {
  display: flex;
  flex-wrap:wrap;
  justify-content: space-between;
  border: none;
  margin: 0;
  padding: 4rem 3.5rem 4rem;
}

fieldset.orange {
  background-color: var(--orange);
  color: var(--green);
}

fieldset.orange div {
  font-size: 1em;
}

label {
  font-size: inherit;
}

small{
  font-size: 0.6em;
  line-height: 1.5em;
}


</style>
