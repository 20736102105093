<template>
  <main>
    <resultForm v-if='$route.params.submission' :submissionProp='$route.params.submission' />

    <footer>
      <nav>
        <downloadButton v-if='$route.params.submission' :submissionID='$route.params.submission'>
          Lataa vastaukset
        </downloadButton>
      </nav>
    </footer>
  </main>
</template>

<script>
import mixins from '.././mixins.js';

export default {
  mixins: [mixins]
}
</script>

<style scoped>
  main {
    height: 100%;
    position: relative;
  }

  footer {
    color: #ff5100;
    position: fixed;
    height: 6rem;
    padding-bottom: 3rem;
    bottom: 6rem;
    left: 0;
    right: 0;
    z-index: 2;
    box-sizing: border-box;
  }
</style>
