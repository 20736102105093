<template>
  <main id='Intro' v-if='kirby.page.template == "cover"'>
    <section id='Top'>
      <!--<img class='layout' src='assets/views/layouts/frontti.jpg'>-->
      <h1>
        <span v-html='kirby.page.strings.masthead' />
      </h1>
      <big v-html='kirby.page.strings.subtitle' />

      <figure>
        <scrolllistener class='mask' v-slot='{ scroll }'>
          <img :style='"transform: scale("+(0.5 + scroll)+")"' src='assets/views/layouts/googlekuva.png'>
        </scrolllistener>
      </figure>
    </section>

    <articlewithhr :content='kirby.page.strings.body'>
      <button @click='$router.push({ path: "tilaus" })' v-html='kirby.page.strings.button' />
    </articlewithhr>

    <section v-if='kirby.page.strings.experiencestitle' id='Hype'>
      <big class="centered" v-html='kirby.page.strings.experiencestitle' />
      <div>
        <summary v-for='experience in yaml(kirby.page.strings.experiences)' :key='experience' v-html='experience.content' />
      </div>
    </section>

    <mainFooter />
  </main>
</template>

<script>
import mixins from '.././mixins.js';

export default {
  mixins: [mixins], //kirby(), urltopath(), etc
}
</script>


<style scoped>

main#Intro {
  width: 100vw;
  overflow: hidden;
  background-color: var(--green);
  color: white;
}

section#Top {
  background-color: #ff5100;
  height: 51.25rem;
  position: relative;
}

section#Hype {
  padding: 3rem;
  display: block;
  position: relative;
  border: 1px solid transparent;
  border-top-color: #ff5100;
  border-bottom-color: #ff5100;
  overflow: hidden;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

section#Hype div {
  display: flex;
  text-align: center;
  align-content: center;
  flex-wrap: wrap;
  justify-content: center;
}

section#Hype div summary {
  font-size: 1.5em;
  max-width: 28.5rem;
  position: relative;
  font-family: 'SimplonNorm-Light';
  flex-grow: 1;
  padding: 4rem;
  text-align: left;
  align-content: center;
}

section#Hype div summary::before {
  position: absolute;
  top: 3.5rem;
  left: 0.75rem;
  font-size: 2em;
  content: "❝";
  color: #ff5100;
}

figure {
  font-size: 80vh;
  text-align: center;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 50vw;
  padding: 0;
  box-sizing: border-box;
  margin: 0;
  /*background-color: #294634;*/
  overflow: hidden;
}

figure .mask {
  display: block;
  height: 44rem;
  width: 44rem;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 6rem;
  margin: 0;
  border-radius: 50%;
  overflow: hidden;
  margin: auto;
  background-color: #294634;
}

figure .mask img {
  position: absolute;
  left: -100%;
  right: -100%;
  top: -300%;
  bottom: -300%;
  margin: auto;
}


img.mesh {
  position: absolute;
  left: 50vw;
  bottom: 0rem;
  transform: translateY(20vh);
}

h1 {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 50vw;
  padding: 11rem 2.5rem 0rem;
  box-sizing: border-box;
  font-family: 'SimplonNorm-Light';
  font-weight: lighter;
  color: #ffffff;
  font-weight: 100;
  letter-spacing: -0.025em;
  font-size: 6.9rem;
  line-height: 0.925em;
  margin: 0;
}

big {
  font-family: 'SimplonNorm-Light';
  letter-spacing: -0.010em;
  font-size: 2.5rem;
  display: block;
  line-height: 1.05em;
  width: 50vw;
  padding: 1.25rem 11rem 4rem 2.5rem;
  box-sizing: border-box;
  color: #294634;
}

big.centered {
  font-family: 'SimplonNorm-Light';
  text-align: center;
  color: white;
  width: 100%;
}

article {
  font-family: 'SimplonNorm-Light';
  letter-spacing: -0.010em;
  font-size: 1.5rem;
  display: block;
  line-height: 1.5em;
  width: 56rem;
  padding: 2.75rem 3.5rem 4rem;
  margin: auto;
  box-sizing: border-box;
  border: 1px solid rgba(255,81,0,0.5);
  border-top: 0px solid transparent;
}

button {
  display: block;
  margin-top: 3em;
  margin-bottom: 3em;
  margin-left: auto;
  margin-right: auto;
  color: #ff5100;
  font-size: 1.33rem;
  font-family: 'SimplonMono-Light';
  border-radius: 2em;
  background-color: white;
  padding: 1.4rem 2rem;
  transition: background-color 0.2s ease;
  text-transform: uppercase;
}

button:hover {
  color: white;
  background-color: #ff5100;
  cursor: pointer;
}

</style>
