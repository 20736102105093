<template>
  <main id='Landing'>
    <router-view />
  </main>
</template>

<style scoped>

  main#Landing {
    height: 100%;
  }

</style>

<script>
import mixins from '.././mixins.js';

export default {
  mixins: [mixins], //kirby(), urltopath(), etc
  mounted: function() {
    let self = this;
    this.$root.$on('nextchapter', function(){
      this.$root.$emit('clear');
      let next = self.kirby.page.next;
      if(next) self.$router.push({ path: '../'+self.kirby.page.next });
    });
  }
}
</script>
