<template>
  <section class='video'>
    <vimeo-player
      ref='player'
      :options='video.options'
      :video-id='slide.content.url'
      player-height='auto'
      @ended='ended' />
    <button @click='toggle()' :class='video.playing ? "pause" : "play"'>
      <svg class='play'
           version='1.1'
           xmlns='http://www.w3.org/2000/svg'
           height='89px'
           viewBox='0 0 89 89'
           xml:space='preserve'>
        <path d='M44.5,0C19.9,0,0,19.9,0,44.5S19.9,89,44.5,89S89,69.1,89,44.5S69.1,0,44.5,0z M31.2,62.1V28.2l33.9,16.9L31.2,62.1z' />
      </svg>
    </button>
  </section>
</template>

<script>
module.exports = {
  props: ['slide', 'active'],
  watch: {
    active: {
      immediate: true,
      deep: true,
      handler: 'activeListener'
    }
  },
  data: function(){
    return {
      video: {
        options: {
          controls: false,
          responsive: true,
          dnt: true
        },
        playing: false,
      }
    }
  },
  mounted: function(){
    //Listeners
    this.$root.$on('next', this.pause);
    this.$root.$on('prev', this.pause);
  },
  methods: {
    ended: function() {
      this.$root.$emit('next');
    },
    onReady() {
      this.playerReady = true;
    },
    activeListener: function() {
      //Play when active
      if(this.active && this.$refs.player) {
        this.$refs.player.play();
        this.video.playing = true;
      } else if(this.active) {
        let self = this;
        //setTimeout(function(){ self.activeListener() }, 500);
      }
      //Pause when not active
      if(!this.active && this.$refs.player) {
        this.$refs.player.pause();
        this.video.playing = false;
      }
    },
    toggle() {
      if(this.video.playing) {
        this.$refs.player.pause();
        this.video.playing = false;
      } else {
        this.$refs.player.play();
        this.video.playing = true;
      }
    },
    pause() {
      if(this.video.playing) {
        this.$refs.player.pause();
        this.video.playing = false;
      }
    }
  }
}
</script>

<style scoped>
section.video {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  height: calc( 100% - 16rem );
  max-width: 130vh;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

button {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

button.pause svg {
  opacity: 0;
}

button svg {
  transition: opacity 0.5s;
width: 6em;
height: 6em;
fill: white;
stroke: transparent;
}
</style>
