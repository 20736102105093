<template>
  <big class='text slide' v-html='slide.content.text' />
</template>

<script>
import mixins from '../.././mixins.js';

export default {
  mixins: [mixins], //kirby(), urltopath(), etc
  props: ['slide']
}
</script>

<style scoped>
  big {
    font-family: 'SimplonNorm-Light';
    color: var(--green);
    text-align: center;
  }
</style>

<style>
  big.text.slide p {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
</style>
