<template>
  <div>
    <slot :scroll='scroll' />
  </div>
</template>

<script>
export default {
  data: function(){
    return {
      scroll: 0
    }
  },
  created () {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll (event) {
      this.scroll = (window.scrollY / (document.documentElement.scrollHeight - window.innerHeight));
    }
  }
}
</script>
