<template>
  <form>
    <label>{{ slide.content.questiontext }}</label>
    <textarea v-model='message' @blur='post(slide.content.question, message)' placeholder='Kopioi tai lisää vastaukset tähän.' />
    <audiofooter v-if='audio'
                 :url='audio'
                 :active='active'
                 :warning='warning'
                 :before='slide.content.before'
                 :autocontinue='false'
                 :timer='timestampToSeconds(slide.content.timer)' />
    <interruption :active='active' :timer='slide.content.interruption' />
  </form>
</template>

<style type="text/css" scoped>
  form {
    margin: 3em;
    position: relative;
    top: -3em;
    display: flex;
  }

  label {
    font-family: 'SimplonNorm-Light';
    letter-spacing: 0.02em;
    font-size: 3.8em;
    display: block;
    width: 50%;
    padding-right: 6rem;
    box-sizing: border-box;
  }

  textarea {
    font-family: inherit;
    font-size: 1.5em;
    display: block;
    width: 50%;
    min-height: 20em;
    padding: 3em;
    border-radius: 0.75em;
    box-sizing: border-box;
    border: none;
    outline: none;
    resize: none;
    overflow-y: scroll;
    scrollbar-width: thin;
  }

  textarea::-webkit-scrollbar-thumb {
    width: 12px;
    background-color: var(--orange);
    border: 4px solid transparent;
    border-radius: 8px;
    background-clip: padding-box;
  }

  textarea::-webkit-scrollbar {
    width: 16px;
  }


</style>

<script>
import mixins from '../.././mixins.js';

export default {
  props: ['slide', 'active', 'warning'],
  mixins: [mixins], //kirby(), urltopath(), etc
  data: function(){
    return {
      message: '',
    }
  },
  computed: {
    audio: function() {
      if(this.kirby.page.uid == 'home' && this.slide.content.source[0]) {
        return this.kirby.page.url + '/home/' + this.slide.content.source[0];
      } else if (this.slide.content.source) {
        return this.kirby.page.url + '/' + this.slide.content.source[0];
      }
      return ''
    }
  }
}
</script>
