<template>
  <main id='Terms' v-if='kirby.page.template'>
    <articlewithhr :content='kirby.page.strings.body' />
    <mainFooter />
  </main>
</template>

<script>
import mixins from '.././mixins.js';

export default {
  mixins: [mixins], //kirby(), urltopath(), etc
}
</script>

<style scoped>
header {
  position: absolute;
  padding: 2.5rem 2.75rem;
  margin-bottom: 5rem;
  color: var(--gray);
  font-family: 'SimplonMono-Medium';
  text-transform: uppercase;
  font-size: 1.4rem;
}


main#Intro {
  width: 100vw;
  overflow: hidden;
  color: #294634;
  background-color: var(--gray);
}

big {
  font-family: 'SimplonNorm-Light';
  letter-spacing: -0.035em;
  font-size: 2.75rem;
  display: block;
  line-height: 0.95em;
  width: 50vw;
  padding: 3.5rem 2.5rem 4rem;
  box-sizing: border-box;
  border-bottom: 1px solid rgba(255,255,255,0.75);
}

article {
  font-family: 'SimplonNorm-Light';
  letter-spacing: -0.010em;
  font-size: 1.5rem;
  display: block;
  line-height: 1.5em;
  width: 56rem;
  padding: 9.5rem 3.5rem 4rem;
  margin: auto;
  box-sizing: border-box;
  border: 1px solid rgba(255,81,0,0.5);
  border-top: 0px solid transparent;
  border-bottom: 0px solid transparent;
}

section {
  border-top: 1px solid rgba(255,81,0,0.5);
  border-bottom: 1px solid rgba(255,81,0,0.5);
}

section > div {
  width: 56rem;
  padding: 4rem 3.5rem 4rem;
  box-sizing: border-box;
  border-left: 1px solid rgba(255,81,0,0.5);
  border-right: 1px solid rgba(255,81,0,0.5);
  margin: auto;
}

section > div.columns {
  display: flex;
}

section > div.columns > * {
  width: 50%;
}

h2 {
  font-size: 1.66em;
}

dl {
  font-size: 1.4rem;
  font-family: 'SimplonNorm-Light';
}

dt {
  text-transform: uppercase;
}

dd {
  margin: 0;
  padding: 0;
  margin-bottom: 1.2em;
  line-height: 1.2em;
}

footer {
  position: relative;
  padding: 6rem 2.5rem 5rem;
  background-color: #294634;
  width: 100vw;
  z-index: 1;
  border-top: 1px solid rgba(255,81,0,0.5);
  margin-top: -1px;
}

.logo {
  height: 3rem;
  fill: white;
}

figure {
  font-size: 80vh;
  text-align: center;
  position: fixed;
  right: 0;
  top: 0;
  height: 100vh;
  width: 50vw;
  padding: 0;
  box-sizing: border-box;
  margin: 0;
  border-left: 1px solid rgba(255,255,255,0.75);
  /* background-color: #294634; */
}

figure .mask {
  display: block;
  height: calc( 50vw - 6rem );
  width: calc( 50vw - 6rem );
  position: fixed;
  top: 0;
  bottom: 0;
  left: 50vw;
  right: 0;
  margin: auto;
  border-radius: 50%;
  overflow: hidden;
}

img.mesh {
  position: absolute;
  left: 50vw;
  bottom: 0rem;
  transform: translateY(20vh);
}

hr {
  display: block;
  background-color: red;
}

</style>
