import Vue from 'vue';
import marked from 'marked';
import axios from 'axios';
import yaml from 'yaml-js/lib/yaml.js';

import GlobalEvents from 'vue-global-events';
Vue.component('GlobalEvents', GlobalEvents);

//import vueVimeoPlayer from 'vue-vimeo-player'
//Vue.use(vueVimeoPlayer)

export default {
  data: function(){
    return {
      loading: false,
    }
  },
  computed: {
    kirby () {
      return this.$store.state.kirby
    },
    submissionID () {
      return this.$store.state.submissionID
    },
  },
  methods: {
    urltopath (url) {
      let output = new URL(url);
      return output.pathname;
    },
    md (input) {
      return marked(input);
    },
    timestampToSeconds: function(input){
      return new Date('1970-01-01T' + input + 'Z').getTime() / 1000;
    },
    post: function(question, answer) {
      //console.log(question, answer, this.submissionID);
      this.$store.state.loading = true;
      let self = this;
      let submission = {};
      submission[question] = answer;
      if(!this.submissionID) {
        console.info('Loading');
        axios.post('https://eu-api.jotform.com/form/213123051316035/submissions?apikey=37ad870f986a180eba6ae3d27fcc7d9f', submission).then(function(response){
          //console.info(response);
          self.$store.commit('startSubmission', response.data.content.submissionID);
          //self.$store.state.loading = false;
        });
      } else {
        axios.post('https://eu-api.jotform.com/submission/'+this.submissionID+'?apikey=37ad870f986a180eba6ae3d27fcc7d9f', submission).then(function(response){
          console.info(response);
          //self.$store.dispatch('startSubmission', response.data.content.submissionID);
          //self.$store.state.submissionID = ;
          //self.$store.state.loading = false;
        });
      }
    },
    yaml (input) {
      return yaml.load(input);
    },
    pageFromURI(uri) {
      let pages = this.kirby.pages;
      if (pages && uri) {
        var paths = uri.split('/');
        var page = false;
        var children = pages;
        while (paths.length) {
          var id = paths.shift();
          //console.log(id);
          page = children[id];
          if(page) children = typeof page.children === 'object' ? page.children : [];
        }
        if(page) {
          return page;
        } else {
          //console.error('No page!', uri, pages);
        }
      } else {
        //console.error('No pages! Add a page element with function', uri, pages);
      }
    }
  }
}
