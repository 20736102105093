/* Components */
import Vue from 'vue';

import playorpause from './playorpause.vue';
Vue.component('playorpause', playorpause);

import arrow from './arrow.vue';
Vue.component('arrow', arrow);

import wiseLogo from './wiselogo.vue';
Vue.component('WiseLogo', wiseLogo);

import tutkimusLogo from './tutkimuslogo.vue';
Vue.component('TutkimusLogo', tutkimusLogo);

import articlewithhr from './articlewithhr.vue';
Vue.component('articlewithhr', articlewithhr);

import mainFooter from './mainFooter.vue';
Vue.component('mainFooter', mainFooter);
