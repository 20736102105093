<template>
  <section>
    <big v-html='slide.content.text' :class='slide.content.style' />
    <slideImage :filename='slide.content.image ? slide.content.image[0] : false' />
    <audiofooter v-if='audio'
                 :url='audio'
                 :active='active'
                 :warning='warning'
                 :before='slide.content.before'
                 :after='slide.content.after'
                 :autocontinue='slide.content.autocontinue' />
    <interruption :active='active' :timer='slide.content.interruption' />
  </section>
</template>

<style scoped type="text/css">
  big {
    font-family: 'SimplonNorm-Light';
    letter-spacing: 0.02em;
    font-size: 3.8em;
    display: block;
    padding: 0em;
    transform: translateY(-3rem);
    margin: 3rem;
    position: relative;
    top: -3rem;
  }

  big.circle {
    font-family: 'SimplonMono-Light' !important;
    font-weight: lighter !important;
    font-size: 12rem !important;
    background-color: orange;
    color: white !important;
    border-radius: 50%;
    display: inline-block;
    width: 25rem;
    box-sizing: border-box;
    height: 25rem;
    padding-top: 0.25rem;
    padding-right: 0.25rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    background-color: rgba(255,81,0,0.95);
  }
</style>

<script>
import mixins from '../.././mixins.js';

export default {
  mixins: [mixins], //kirby(), urltopath(), etc
  props: ['slide', 'active', 'warning'],
  data: function() {
    return {
      paused: false,
      progress: 0,
    }
  },
  computed: {
    audio: function() {
      if(this.kirby.page.uid == 'home' && this.slide.content.source[0]) {
        return this.kirby.page.url + '/home/' + this.slide.content.source[0];
      } else if (this.slide.content.source) {
        return this.kirby.page.url + '/' + this.slide.content.source[0];
      }
      return '';
    }
  }
}
</script>
