<template>
  <nav>
    <a class='toggle'>
      <playorpause class='small centered' :paused='paused' />
    </a>
    <time>
      <Countdown :time="10800" format="hh:mm,ss" @on-end="onCountdownEnd">
        <template slot-scope="{ time }">{{ time }}</template>
      </Countdown>
    </time>
  </nav>
</template>

<script>
import Countdown from '@choujiaojiao/vue2-countdown'

export default {
  components: { Countdown },
  methods: {
    onCountdownEnd() {
      console.log('countdown end~')
    }
  }
}
</script>

<style scoped>
  nav {
    position: absolute;
    top: 3rem;
    right: 3rem;
    vertical-align: top;
  }

  a {
    vertical-align: top;
    top: 0;
    bottom: 0;
    margin: auto;
    position: relative;
    width: 1rem;
    display: inline-block;
    background-color: #ff5100;
    height: 3rem;
    line-height: 1.6em;
    padding: 0.25rem 1.125rem 0.125rem;
    border-radius: 50%;
    font-family: 'SimplonMono-Light';
    font-size: 2rem;
    color: white;
    user-select: none;
    font-weight: lighter;
    margin-right: 1rem;
  }

  figure.small.centered {
    transform: scale(0.6);
  }

  time {
    vertical-align: top;
    display: inline-block;
    min-width: 6rem;
    background-color: #ff5100;
    line-height: 1.6em;
    padding: 0.25rem 1.125rem 0.125rem;
    border-radius: 1rem;
    font-family: 'SimplonMono-Light';
    font-size: 2rem;
    color: white;
    user-select: none;
    font-weight: lighter;
  }
</style>
