/* Components */
import Vue from 'vue';

import jotform from './jotform.vue';
Vue.component('Jotform', jotform);

import esivalmistelu from './esivalmistelu.vue';
Vue.component('Esivalmistelu', esivalmistelu);

import videoslide from './video.vue';
Vue.component('Videoslide', videoslide);

import audioslide from './audio.vue';
Vue.component('Audioslide', audioslide);

import titleslide from './titleslide.vue';
Vue.component('Titleslide', titleslide);

import counterslide from './counter.vue';
Vue.component('Counterslide', counterslide);

import textslide from './text.vue';
Vue.component('Textslide', textslide);
