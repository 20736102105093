/* Components */
import Vue from 'vue';

//Groups
import slides from './slides';
import micros from './micros';

import srcset from './srcset.vue';
Vue.component('Srcset', srcset);

import vSelect from 'vue-select';
Vue.component('VSelect', vSelect);

import slideshow from './slideshow.vue';
Vue.component('Slideshow', slideshow);

import navigation from './navigation.vue';
Vue.component('Navigation', navigation);

import vueVimeoPlayer from 'vue-vimeo-player';
Vue.use(vueVimeoPlayer);

import AudioVisual from 'vue-audio-visual';
Vue.use(AudioVisual);

import audiofooter from './audiofooter.vue';
Vue.component('Audiofooter', audiofooter);

import timer from './timer.vue';
Vue.component('Timer', timer);

import scrolllistener from './scrolllistener.vue';
Vue.component('Scrolllistener', scrolllistener);

import masterclock from './masterclock.vue';
Vue.component('Masterclock', masterclock);

import slideImage from './slideImage.vue';
Vue.component('slideImage', slideImage);

import slideProgress from './slideProgress.vue';
Vue.component('slideProgress', slideProgress);

import kirbyForm from './kirbyForm.vue';
Vue.component('kirbyForm', kirbyForm);

import interruption from './interruption.vue';
Vue.component('interruption', interruption);

import downloadButton from './downloadButton.vue';
Vue.component('downloadButton', downloadButton);

import resultForm from './resultForm.vue';
Vue.component('resultForm', resultForm);

import customHr from './customHr.vue';
Vue.component('customHr', customHr);
