/* Router */

import Landing from './views/landing.vue';
import Start from './views/start.vue';
import Cover from './views/cover.vue';
import Default from './views/default.vue';
import Results from './views/results.vue';
import Terms from './views/terms.vue';


export default [
  {
    path: '/',
    component: Landing,
    children: [
      {
        name: 'Cover',
        path: '',
        component: Cover
      },
      {
        name: 'Start',
        path: 'tilaus',
        component: Start
      },
      {
        path: '/harjoitus',
        name: 'Default',
        component: Default,
      },
      {
        path: 'tietosuoja(.*)',
        name: 'Tietosuoja',
        component: Terms,
      },
      {
        path: 'harjoitus/tulokset/:submission',
        name: 'Results',
        component: Results
      }
    ]
  },
]
