<template>
  <article class="withhr">
    <v-runtime-template :template='modifiedcontent' />
    <slot />
  </article>
</template>

<style type="text/css">

  article.withhr hr {
    display: block;
    position: relative;
    margin: 0 -3.5rem;
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
    left: 0;
    left: calc(-50vw + 28rem);
    width: 100vw;
    border-color: transparent;
    border-top-color: #ff5100;
    border-bottom-color: #ff5100;
    height: 20rem;
  }

  article.withhr h4  {
    margin-bottom: 0.5rem;
  }
</style>

<script>
import VRuntimeTemplate from 'v-runtime-template';

export default {
  props: ['content'],
  components: { VRuntimeTemplate },
  computed: {
    modifiedcontent: function(){
      var returnContent = this.content.replaceAll('<hr>','<customHr />')
      return '<section>'+returnContent+'</section>';
    }
  }
}
</script>
