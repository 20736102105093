<template>
  <time>
    <Countdown :time='timestampToSeconds(slide.content.timer)' format='hh:mm,ss' @on-end='onCountdownEnd'>
      <template slot-scope='{ time }'>{{ time }}</template>
    </Countdown>
  </time>
</template>

<script>
import Countdown from '@choujiaojiao/vue2-countdown'

export default {
  props: ['slide'],
  components: { Countdown },
  methods: {
    onCountdownEnd() {
      this.$root.$emit('activateNextArrow');
    },
    timestampToSeconds: function(input){
      return new Date('1970-01-01T' + input + 'Z').getTime() / 1000;
    }
  }
}
</script>

<style scoped>
  time {
    width: 100%;
    text-align: center;
    vertical-align: top;
    display: block;
    font-family: 'SimplonMono-Light';
    font-size: 15vw;
    color: white;
    user-select: none;
    font-weight: lighter;
  }
</style>
